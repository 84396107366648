import { useState, useEffect } from "react";

import optionsSelectorInformation from "../components/options-selectors";

import { appName } from "../contexts/constants";
import sharedStateDefinitions from "./tab-info-files/sharedStateDefinitions.js";

import checkIfArraysContainSameValues from "../utilities/compareArrays.js";

// This should be a list of the files in '/src/hooks/tab-info/'
const TAB_FILE_NAMES = [
  'chill',
  'gdds',
  'heavy-rain'
];

function combineWithDefaults(definedSelectorInformation, optionsSelectorInformation, subTabValue) {
  // Extract information from the options selector definitions
  const { component, defaultProps } = optionsSelectorInformation[definedSelectorInformation.component];
  
  // Initialize the return object with as much info as possible
  const returnObj = {
    name: definedSelectorInformation.component,
    id: definedSelectorInformation.id,
    component,
    persist: 'persist' in definedSelectorInformation ? Boolean(definedSelectorInformation.persist) : true,
    props: JSON.parse(JSON.stringify(defaultProps)),
    useSharedState: definedSelectorInformation.useSharedState || false
  };

  // If there are props defined, iterate over them transforming where necessary and overwriting the defaults that were added during initialization
  //   Add in extra transformations here if needed!!!
  if (definedSelectorInformation.props && Object.keys(definedSelectorInformation.props).length) {
    for (const [key, value] of Object.entries(definedSelectorInformation.props)) {
      if (value === 'gddSelectedSubTab') {
        returnObj.props[key] = parseInt(subTabValue.slice(5));
      } else if (value === 'heavyRainSelectedSubTab') {
        returnObj.props[key] = parseFloat(subTabValue.slice(1,4));
      } else {
        returnObj.props[key] = value;
      }
    }
  }

  // If not using shared state and the value should be persisted, check for a stored value to overwrite the initialized value with
  if (!returnObj.useSharedState && returnObj.persist) {
    let storedValues = localStorage.getItem(`${appName}.${returnObj.id}-${subTabValue}-options`) || null;
    if (storedValues) {
      storedValues = JSON.parse(storedValues);

      // make sure the value is a valid option if there are options to choose from
      if (returnObj.name === 'listSelector' && new Set(storedValues.value).isSubsetOf(new Set(returnObj.props.options))) {
        returnObj.props.value = storedValues.value;
      } else if (returnObj.name === 'thresholdSelector') {
        returnObj.props.value = storedValues.value;
      } else {
        if (storedValues && returnObj.props.options.includes(storedValues.value)) {
          returnObj.props.value = storedValues.value;
        }
      }
    }
  }

  return returnObj;
}

function constructInfoObject(baseObj) {
  // Create 'chart' and 'map' information objects that contain the info to construct the options panel for the page
  return ['chart', 'map'].reduce((acc, type) => {
    let options = [];
    if (baseObj.optionsPanel && baseObj.optionsPanel[type] && baseObj.optionsPanel[type].length) {
      options = baseObj.optionsPanel[type].map((selectorObj, i) => combineWithDefaults(selectorObj, optionsSelectorInformation, baseObj.name, i));
    }

    acc[type] = { options };
    return acc;
  }, {});
}

export default function useTabInformation() {
  const [tabInformation, setTabInformation] = useState([]);
  const [tabsSharedState, setTabsSharedState] = useState({});
  
  
  useEffect(() => {
    (async () => {
      // Dynamically load each tab file and create the tab state it needs
      const tabInstances = await Promise.all(TAB_FILE_NAMES.map(async (filename) => {
        // Load tab info file
        const tabInfo = (await import(`./tab-info-files/${filename}.js`)).default;

        // Instanstiate info object structure
        const tabInfoObj = {
          name: tabInfo.name,
          navInfo: {
            name: tabInfo.name,
            subTabs: []
          },
          chartInfo: tabInfo.chartInfo || {},
          mapInfo: tabInfo.mapInfo || {},
          pageInfo: {},
          onChange: ('onChange' in tabInfo ? tabInfo.onChange : []),
          componentFns: tabInfo.componentFns || {},
          textPanelInfo: tabInfo.textPanelInfo || [],
        };

        //  If there are subtabs defined loop them and add their info, otherwise create a 'none' key for the main tab and do the same
        if (tabInfo.subTabs) {
          if (Array.isArray(tabInfo.subTabs)) {
            for (const subTabInfo of tabInfo.subTabs) {
              tabInfoObj.navInfo.subTabs.push(subTabInfo.name);
              tabInfoObj.pageInfo[subTabInfo.name] = constructInfoObject({ ...subTabInfo, optionsPanel: tabInfo.optionsPanel});
            }
          } else {
            tabInfoObj.navInfo.subTabs = {};
            for (const [groupName, groupSubTabs] of Object.entries(tabInfo.subTabs)) {
              tabInfoObj.navInfo.subTabs[groupName] = [];
              for (const subTabInfo of groupSubTabs) {
                tabInfoObj.navInfo.subTabs[groupName].push(subTabInfo.name);
                tabInfoObj.pageInfo[subTabInfo.name] = constructInfoObject({ ...subTabInfo, optionsPanel: tabInfo.optionsPanel});
              }
            }
          }
        } else {
          tabInfoObj.pageInfo['none'] = constructInfoObject(tabInfo);
        }
        
        // Return state object for the tab
        return tabInfoObj;
      }));
      
      // Set the tabs
      setTabInformation(tabInstances);
    })();

    (async () => {
      // Iterate over shared state definitions, creating shared state objects for each
      const newTabsSharedState = Object.entries(sharedStateDefinitions).reduce((acc,[sharedStateName, stateObj]) => {
        // Initialize obj
        acc[sharedStateName] = {
          persist: ('persist' in stateObj ? Boolean(stateObj.persist) : true),
          onNavChange: ('onNavChange' in stateObj ? stateObj.onNavChange : null),
          onInitialize: ('onInitialize' in stateObj ? stateObj.onInitialize : null),
          onLocationChange: ('onLocationChange' in stateObj ? stateObj.onLocationChange : null),
          ...('props' in stateObj ? stateObj.props : {})
        };

        // Check if there are options defined
        const hasOptions = 'options' in stateObj;

        // If there are options, add them to the return
        if (hasOptions) {
          acc[sharedStateName].options = stateObj.options;
        }

        // Initialize the shared value
        acc[sharedStateName].resetValue = stateObj.initValue;
        let sharedValue = stateObj.initValue;

        // If persisting the value, check if there is a valid stored value to use instead of the initial value
        if (acc[sharedStateName].persist) {
          // Try to load from localStorage
          let storedValue = localStorage.getItem(`${appName}.${sharedStateName}-shared-state-value`) || null;

          // If a stored value if found
          if (storedValue) {
            // All stored values are stringified, so they should all be parsed when loaded
            storedValue = JSON.parse(storedValue);
            
            // Use the stored unless options are defined and the stored value is not a valid option
            let validValue = true;
            if (hasOptions) {
              // If typeof is 'object' the value is a list - check if the value is a subset of the valid options
              //             else the value is a primitive - check if the valus is in the list of valid options
              validValue = typeof storedValue === 'object' ? new Set(storedValue).isSubsetOf(new Set(stateObj.options)) : stateObj.options.includes(storedValue);
            }

            // If the value is valid, change the shared value to is
            if (validValue) {
              sharedValue = storedValue;
            }
          }
        }

        // Add the shared value to the return
        acc[sharedStateName].value = sharedValue;
        
        return acc;
      }, {});

      setTabsSharedState(newTabsSharedState);
    })();
  }, []);

  function updateTabSharedState({ sharedStateNameToEdit, newValue }) {
    if (sharedStateNameToEdit !== undefined && newValue !== undefined) {
      // Copy shared state
      let newTabsSharedState = {...tabsSharedState};
  
      // Change target value
      newTabsSharedState[sharedStateNameToEdit].value = newValue;
  
      // Save change to local storage if persisting
      if (newTabsSharedState[sharedStateNameToEdit].persist) {
        localStorage.setItem(`${appName}.${sharedStateNameToEdit}-shared-state-value`, JSON.stringify(newValue));
      }
  
      // Update state object
      setTabsSharedState(newTabsSharedState);
      return newTabsSharedState;
    } else {
      console.error(`Invalid kwargs: sharedStateNameToEdit - ${sharedStateNameToEdit}    newValue: `, newValue);
    }
    return null;
  }
  
  function updateTabSharedStateOnChange(onChangeFnName, kwargs, updatedData) {
    // Flag to determine if an update is needed
    let didUpdate = false;

    const newTabsSharedState = Object.entries(updatedData.tabsSharedState).reduce((acc, [sharedStateName, stateObj]) => {
      // Make copy of state object
      const newStateObj = {...stateObj};

      // If a function is not provided to handle this situation then do nothing
      if (stateObj[onChangeFnName] !== null && stateObj[onChangeFnName] !== undefined) {
        // Otherwise, call the function with the new tab and subtab names
        const newValue = stateObj[onChangeFnName](stateObj.value, kwargs, updatedData);
        
        // True if the old value is a function (would lead to an error), the new value is a list and is different from the old value, or if the new value is a primitive and is not equal to the old value
        if (typeof stateObj.value === 'function' || (typeof newValue === 'object' && !checkIfArraysContainSameValues(newValue, stateObj.value)) || (typeof newValue !== 'object' && stateObj.value !== newValue)) {
          // Change the value in the return object
          newStateObj.value = newValue;

          // Save the change to local storage if persisting
          if (stateObj.persist && typeof newValue !== 'function') {
            localStorage.setItem(`${appName}.${sharedStateName}-shared-state-value`, JSON.stringify(newValue));
          }

          // Change the flag to indicate that we need to update state
          didUpdate = true;
        }
      }

      acc[sharedStateName] = newStateObj;
      return acc;
    }, {});

    // If the flag is true, update the state
    if (didUpdate) {
      setTabsSharedState(newTabsSharedState);
      return newTabsSharedState;
    } else {
      return tabsSharedState;
    }
  }

  function updateTabOptions({ tabToUpdate, subTabToUpdate, pageToUpdate, selectorIndexToUpdate, keyToUpdate, newValue, persist }) {
    if (
      tabToUpdate  !== undefined &&
      subTabToUpdate  !== undefined &&
      pageToUpdate  !== undefined &&
      selectorIndexToUpdate  !== undefined &&
      keyToUpdate  !== undefined &&
      newValue  !== undefined &&
      persist  !== undefined
    ) {
      // Make a copy of the tab information list
      let newTabs = [...tabInformation];

      // Try to find the targeted tab, update it if found
      const tabIdx = newTabs.findIndex(tab => tab.name === tabToUpdate);
      if (tabIdx !== -1) {
        newTabs[tabIdx].pageInfo[subTabToUpdate][pageToUpdate].options[selectorIndexToUpdate].props[keyToUpdate] = newValue;
      }

      // Save the change to local storage if persisting
      if (persist) {
        const componentInfo = newTabs[tabIdx].pageInfo[subTabToUpdate][pageToUpdate].options[selectorIndexToUpdate];
        localStorage.setItem(`${appName}.${componentInfo.id}-${subTabToUpdate}-options`, JSON.stringify(componentInfo.props));
      }

      // Update state
      setTabInformation(newTabs);
      return newTabs;
    } else {
      console.error('Invalid kwargs: ', { tabToUpdate, subTabToUpdate, pageToUpdate, selectorIndexToUpdate, keyToUpdate, newValue, persist });
    }
    return null;
  }

  function getSelectorValueFromTabInformation(id, selectedTab, selectedSubTab) {
    if (tabInformation.length && selectedTab && typeof selectedTab === 'string') {
      const selectedTabInformation = tabInformation.find(tab => tab.name === selectedTab);

      if (id === 'overlayKey') {
        id = selectedTabInformation.mapInfo.overlayKeySelectorId || null;
      } else if (id === 'regions') {
        id = selectedTabInformation.mapInfo.regionsSelectorId || null;
      }

      if (id in tabsSharedState) {
        return tabsSharedState[id].value;
      } else {
        return selectedTabInformation.pageInfo[selectedSubTab].map.options.find(obj => obj.id === id).props.value;
      }
    }

    return null;
  }

  return {
    tabInformation,
    tabsSharedState,
    updateTabFunctions: {
      updateTabOptions,
      updateTabSharedState,
      updateTabSharedStateOnChange
    },
    getSelectorValueFromTabInformation
  };
}