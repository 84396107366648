import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import { MapProvider } from './contexts/map.context';
import { TabInfoProvider } from './contexts/tab-info.context';
import { GlobalProvider } from './contexts/global.context';

import './index.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <TabInfoProvider>
      <GlobalProvider>
        <MapProvider>
          <App />
        </MapProvider>
      </GlobalProvider>
    </TabInfoProvider>
  </React.StrictMode>
);
