import React, { useContext, useState } from 'react';
import { Marker, Popup } from 'react-map-gl';

import roundXDigits from '../../../utilities/round';

import { GlobalContext } from '../../../contexts/global.context';

import { ReactComponent as Pin } from './pin.svg';

import './location-pins.styles.scss';

// Determines if user device is a touchscreen device, returns boolean
function isTouchDevice() {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
}

export default function LocationPins() {
  const [popupLocation, setPopupLocation] = useState(false);

  const { display, toggleDisplay, selectedLocation, pastLocations, handleUpdate } = useContext(GlobalContext);

  // Changes selected location
  const handleMarkerClick = async (e, id) => {
    e.originalEvent.stopPropagation();
    await handleUpdate('changeLocation', { newId: id });
    if (display === 'map') toggleDisplay();
    setPopupLocation(false);
  };

  // Removes locations
  const handleMarkerRightClick = async (id, isSelected) => {
    if (!isSelected) {
      await handleUpdate('removeLocation', { removeId: id });
      setPopupLocation(false);
    }
  };

  return (
    <>
      {Object.keys(pastLocations).map((id) => {
        const loc = pastLocations[id];
        const isSelected = id === selectedLocation.id;

        const pinColor = '#3f3f3f';
        const pinProps = {
          className: 'loc-marker',
          fill: isSelected ? 'white' : pinColor,
          stroke: isSelected ? 'black' : pinColor,
          onMouseEnter: () => setPopupLocation({ ...loc, isSelected }),
          onMouseLeave: () => setPopupLocation(false),
          onContextMenu: () => handleMarkerRightClick(id, isSelected),
        };

        return (
          <Marker
            key={id}
            longitude={loc.lng}
            latitude={loc.lat}
            onClick={(e) => handleMarkerClick(e, id)}
            style={{ zIndex: isSelected ? 2 : 1, top: -20 }}
          >
            <Pin {...pinProps} />
          </Marker>
        );
      })}

      {popupLocation && (
        <Popup
          longitude={popupLocation.lng}
          latitude={popupLocation.lat}
          closeOnClick={false}
          closeButton={false}
          onClose={() => setPopupLocation(false)}
          offset={{
            center: [0, 0],
            left: [12, 0],
            right: [-12, 0],
            top: [0, -6],
            'top-left': [10, 3],
            'top-right': [-20, 3],
            bottom: [0, -16],
            'bottom-left': [10, -12],
            'bottom-right': [-20, -12],
          }}
          style={{ zIndex: 4 }}
        >
          <h3>{popupLocation.address}</h3>
          <h4>
            Coordinates: {roundXDigits(popupLocation.lng, 5)},{' '}
            {roundXDigits(popupLocation.lat, 5)}
          </h4>
          {popupLocation.isSelected ? (
            ''
          ) : (
            <>
              <h5>Click to Use</h5>
              {isTouchDevice() ? (
                <h5>Click and Hold to Remove</h5>
              ) : (
                <h5>Right Click to Remove</h5>
              )}
            </>
          )}
        </Popup>
      )}
    </>
  );
}
