import React, { useState, useEffect, useContext, createContext } from 'react';
import PropTypes from 'prop-types';

import useStoredState from '../hooks/useStoredState';
import { appName, defaultLocation } from './constants';

import { TabInfoContext } from './tab-info.context';

// Set up initial state of context
export const GlobalContext = createContext({
  display: 'map',
  toggleDisplay: () => {},
  selectedTab: '',
  selectedSubTab: '',
  overlayData: null,
  overlayDataDate: null,
  overlayColors: null,
  overlayBinColors: null,
  overlayBinThresholds: null,
  overlayFilename: null,
  updateOverlayData: () => {},
  isLoading: true,
  chartData: {},
  handleUpdate: () => {},
  getSelectorValueByIdFromTabInformation: () => {},
  selectedLocation: null,
  pastLocations: {},
  rawChartData: {}
});

// Set up context provider
export const GlobalProvider = ({ children }) => {
  const [display, setDisplay] = useState('chart');
  // const [display, setDisplay] = useState('map');
  const [loading, setLoading] = useState(['initialize']);
  
  const [selectedTab, setSelectedTab] = useStoredState(`${appName}.selected-tab`, 'Chill Units');
  const [selectedSubTab, setSelectedSubTab] = useStoredState(`${appName}.selected-subtab`, 'North Carolina Model');
  const [selectedLocation, setSelectedLocation] = useStoredState(`${appName}.selected-location`, defaultLocation.id);
  const [pastLocations, setPastLocations] = useStoredState(`${appName}.selected-past-locations`, { [defaultLocation.id]: defaultLocation }, true);

  const [overlayData, setOverlayData] = useState(null);
  const [overlayDataDate, setOverlayDataDate] = useState(null);
  const [overlayColors, setOverlayColors] = useState(null);
  const [overlayBinColors, setOverlayBinColors] = useState(null);
  const [overlayBinThresholds, setOverlayBinThresholds] = useState(null);
  const [overlayFilename, setOverlayFilename] = useState(null);

  const [rawChartData, setRawChartData] = useState({});
  const [chartData, setChartData] = useState({});

  const { tabInformation, tabsSharedState, updateTabFunctions, updateCurrentTabInformation, getSelectorValueFromTabInformation } = useContext(TabInfoContext);
  
  const addToLoading = (keys) => {
    setLoading((prev) => [...prev, ...keys]);
  };

  const removeFromLoading = (key) => {
    setLoading((currLoading) => {
      const newLoading = [...currLoading];
      const idx = newLoading.indexOf(key);
  
      if (idx > -1) {
        newLoading.splice(idx, 1);
      }  
      return newLoading;
    });
  };

  function toggleDisplay() {
    setDisplay(display === 'map' ? 'chart' : 'map');
  }

  function updateOverlayData(newOverlay) {
    handleUpdate('overlayDataDate', { newOverlayDataDate: newOverlay.dataDate });
    setOverlayData(newOverlay.data);
    setOverlayDataDate(newOverlay.dataDate);
    setOverlayColors(newOverlay.colors);
    setOverlayBinColors(newOverlay.binColors);
    setOverlayBinThresholds(newOverlay.binThresholds);
    setOverlayFilename(newOverlay.filename);
  }

  async function handleUpdate(updateType, kwargs={}) {
    // Used to track what has changed for coordinating data updates
    const whatChanged = [];

    // Used to delay state updates so that they all update at the same time, after data is fetched
    const stateUpdates = [];

    // Used to provide new state to update functions
    const updatedStateObjs = {
      selectedTab,
      selectedSubTab,
      tabInformation,
      tabsSharedState,
      selectedLocation,
      pastLocations,
      rawChartData,
      overlayDataDate,
      overlayFilename
    };

    switch (updateType) {
      case 'initialize':
        updatedStateObjs.tabsSharedState = updateTabFunctions.updateTabSharedStateOnChange('onInitialize', kwargs, updatedStateObjs);
        whatChanged.push('initialize');
        break;
      case 'nav':
        // Updates the shared state to reflect click on a new tab and/or subtab
        updatedStateObjs.tabsSharedState = updateTabFunctions.updateTabSharedStateOnChange('onNavChange', kwargs, updatedStateObjs);
    
        // Only update tab if it changed
        if (selectedTab !== kwargs['newTab']) {
          stateUpdates.push(() => setSelectedTab(kwargs['newTab']));
          whatChanged.push('selectedTab');
          updatedStateObjs.selectedTab = kwargs['newTab'];
        }
    
        // Only update subtab if it changed
        if (selectedSubTab !== kwargs['newSubTab']) {
          stateUpdates.push(async () => setSelectedSubTab(kwargs['newSubTab']));
          whatChanged.push('selectedSubTab');
          updatedStateObjs.selectedSubTab = kwargs['newSubTab'];
        }

        break;
      case 'addLocation':
        updatedStateObjs.tabsSharedState = updateTabFunctions.updateTabSharedStateOnChange('onLocationChange', kwargs, updatedStateObjs);

        stateUpdates.push(async () => setSelectedLocation(kwargs['newId']));
        whatChanged.push('selectedLocation');
        updatedStateObjs.selectedLocation = kwargs['newId'];

        stateUpdates.push(async () => setPastLocations(kwargs['newPastLocations']));
        whatChanged.push('pastLocations');
        updatedStateObjs.pastLocations = kwargs['newPastLocations'];
        break;
      case 'changeLocation':
        updatedStateObjs.tabsSharedState = updateTabFunctions.updateTabSharedStateOnChange('onLocationChange', kwargs, updatedStateObjs);

        stateUpdates.push(async () => setSelectedLocation(kwargs['newId']));
        whatChanged.push('selectedLocation');
        updatedStateObjs.selectedLocation = kwargs['newId'];
        break;
      case 'removeLocation':
        if (updatedStateObjs.selectedLocation !== kwargs['removeId']) {
          const newPastLocations = { ...updatedStateObjs.pastLocations };
          delete newPastLocations[kwargs['removeId']];

          stateUpdates.push(async () => setPastLocations(newPastLocations));
          whatChanged.push('pastLocations');
          updatedStateObjs.pastLocations = newPastLocations;
        }
        break;
      case 'tabOptions':
        // Updates tabInformation with changes to options
        updatedStateObjs.tabInformation = updateTabFunctions.updateTabOptions(kwargs);
        whatChanged.push(updatedStateObjs.tabInformation.find(tab => tab.name === updatedStateObjs.selectedTab).pageInfo[kwargs.subTabToUpdate][kwargs.pageToUpdate].options[kwargs.selectorIndexToUpdate].id);
        break;
      case 'tabSharedState':
        // Updates shared state with changes to options
        updatedStateObjs.tabsSharedState = updateTabFunctions.updateTabSharedState(kwargs);
        whatChanged.push(kwargs['sharedStateNameToEdit']);
        break;
      case 'rawChartData':
        whatChanged.push(updateType);
        break;
      case 'overlayDataDate':
        updatedStateObjs.overlayDataDate = kwargs['newOverlayDataDate'];
        whatChanged.push(updateType);
        break;
      default:
        console.error(`Invalid updateType provided: ${updateType}`);
        break;
    }

    if (whatChanged.includes('initialize') || whatChanged.includes('selectedTab')) {
      stateUpdates.push(() => updateCurrentTabInformation(updatedStateObjs));
    }

    // Get information about how and what to update
    const onChangeArray = updatedStateObjs.tabInformation.find(tab => tab.name === updatedStateObjs.selectedTab).onChange;

    // Iterate what has changed and what changes should trigger updates, pushing handler functions to array
    const dataStatePromises = [];
    for (const { whenXChanges, handleChange, showLoading } of onChangeArray) {
      for (const changedName of whatChanged) {
        if (whenXChanges.includes(changedName) || changedName === 'initialize') {
          if (showLoading) {
            stateUpdates.push(() => removeFromLoading(changedName));
            addToLoading([changedName]);
          }
          dataStatePromises.push(handleChange(updatedStateObjs, kwargs, { updateOverlayData, setRawChartData, setChartData }));
          break;
        }
      }
    }

    // Wait for all data fetching function to resolve. Each should return a Promise that, when resolved, returns a setState function
    const dataStateUpdates = await Promise.all(dataStatePromises);

    // Add all of the setState functions together and execute them all
    dataStateUpdates.concat(stateUpdates).forEach(func => func());
  }

  useEffect(() => {
    if (tabInformation.length && loading.filter(n => n === 'initialize').length === 1) {
      handleUpdate('initialize');
      removeFromLoading('initialize');
    }
  }, [tabInformation]);

  useEffect(() => {
    if (Object.keys(rawChartData).length) {
      handleUpdate('rawChartData');
    }
  }, [rawChartData]);

  const value = {
    display,
    toggleDisplay,
    selectedTab,
    selectedSubTab,
    overlayData,
    overlayDataDate,
    overlayColors,
    overlayBinColors,
    overlayBinThresholds,
    overlayFilename,
    updateOverlayData,
    isLoading: loading.length > 0,
    chartData,
    handleUpdate,
    getSelectorValueByIdFromTabInformation: (id) => getSelectorValueFromTabInformation(id, selectedTab, selectedSubTab),
    selectedLocation: pastLocations[selectedLocation],
    pastLocations,
    rawChartData: selectedLocation in rawChartData ? rawChartData[selectedLocation] : {}
  };
  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
};

GlobalProvider.propTypes = {
  children: PropTypes.node,
};
