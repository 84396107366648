import React from 'react';

import Accordion from '../accordion/accordion.component';
import CustomRadioSelect from '../custom-radio-select/custom-radio-select.component';

const defaultProps = {
  options: ['Last Year', 'Normal'],
  value: 'Last Year'
};

export default function DepartureSelector({
  expanded,
  handleChange,
  handleChangeSelection,
  value=defaultProps.value,
  options=defaultProps.options
}) {
  const id = 'departure-selector';
  
  return (
    <Accordion expanded={expanded === id} handleChange={handleChange} id={id} label={`Departure From ${value}`} >
      <CustomRadioSelect
        title='Compare Today To'
        value={value}
        changeFunction={handleChangeSelection}
        options={options}
      />
    </Accordion>
  );
}

export { defaultProps };