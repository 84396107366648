import { parseISO, isBefore, format } from 'date-fns';

import calcTimeseriesOptions from "../../utilities/charts/timeseries-options";
import calcOnTodayValueAmountOptions from "../../utilities/charts/onToday-value-amount-options";
import calcMaxValueDateOptions from "../../utilities/charts/max-value-date-options";
import calcThresholdsOptions from "../../utilities/charts/thresholds-options";
import updateOverlayFromR2 from '../../utilities/overlayUpdateFns';
import { MODELS } from '../../utilities/chill-models';
import { refreshChillChartData, getSplines } from '../../utilities/charts/chart-data-functions';

import { TODAY } from '../../contexts/constants';

const TAB_NAME = 'Chill Units';

const addName = (chartData, address, fn) => {
  return fn(chartData, address, TAB_NAME);
};

const info = {
  name: TAB_NAME,
  subTabs: [
    {
      name: 'North Carolina Model'
    },{
      name: 'Utah Model'
    },{
      name: 'Dynamic Model'
    },
  ],
  chartInfo: {
    chartSelectorId: 'chillUnitsChartSelector',
    charts: [{ 
      name: 'Chill Unit Accumulation Timeseries',
      func: (chartData, address, tabInfoContextState) => addName(chartData, address, calcTimeseriesOptions)
    },{ 
      name: 'Chill Units Achieved On This Date',
      func: (chartData, address, tabInfoContextState) => addName(chartData, address, calcOnTodayValueAmountOptions)
    },{ 
      name: 'Date of Maximum Chill Units',
      func: (chartData, address, tabInfoContextState) => addName(chartData, address, calcMaxValueDateOptions)
    },{ 
      name: 'Date of Threshold Exceedance',
      func: (chartData, address, tabInfoContextState) => addName(chartData, address, calcThresholdsOptions)
    }],
  },
  onChange: [{
    whenXChanges: ['selectedSubTab'],
    handleChange: ({ selectedSubTab }, _, { updateOverlayData }) => {
      const jsonFileName = `${selectedSubTab.split(' ').join('_').toLowerCase()}.json`;
      const jsonFileDataKey = 'data';
      return updateOverlayFromR2(jsonFileName, jsonFileDataKey)
        .then(d => () => updateOverlayData(d));
    },
    showLoading: true
  },{
    whenXChanges: ['selectedLocation', 'selectedTab'],
    handleChange: ({ selectedTab, selectedLocation, pastLocations, overlayDataDate, rawChartData }, _, { setRawChartData }) => {
      if (selectedTab === TAB_NAME) {
        let newRawChartData = { ...rawChartData };
        if (!(selectedLocation in newRawChartData)) {
          newRawChartData = { [selectedLocation]: {} };
        }
        
        if (!(selectedTab in newRawChartData[selectedLocation])) {
          const locInfo = pastLocations[selectedLocation];
          const coords = `${locInfo.lng},${locInfo.lat}`;
          
          const dateObj = overlayDataDate ? parseISO(overlayDataDate) : TODAY;
          const startYear = dateObj.getFullYear() - (isBefore(dateObj, new Date(dateObj.year, 6, 1)) ? 1 : 0) - 31;
          const sdate = `${startYear}-07-01`;
          const edate = format(dateObj, 'yyyy-MM-dd');
  
          return getSplines(coords, sdate, edate)
            .then(splines => {
              newRawChartData[selectedLocation][selectedTab] = splines;
              return () => setRawChartData(newRawChartData);
            });
        }
      }
      
      return () => {};
    },
    showLoading: true
  },{
    whenXChanges: ['chillUnitsThresholdSelector', 'rawChartData', 'selectedSubTab'],
    handleChange: ({ selectedTab, selectedSubTab, selectedLocation, overlayDataDate, tabsSharedState, rawChartData }, _, { setChartData }) => {
      if (selectedTab === TAB_NAME && Boolean(overlayDataDate) && selectedLocation in rawChartData && selectedTab in rawChartData[selectedLocation]) {
        const { newChartData } = refreshChillChartData(overlayDataDate, rawChartData[selectedLocation][selectedTab], tabsSharedState.chillUnitsThresholdSelector.value, MODELS[selectedSubTab]);
        return () => setChartData(newChartData);
      }
      return () => {};
    },
    showLoading: true
  }],
  mapInfo: {
    overlayKeySelectorId: 'departureSelector',
    regionsSelectorId: 'regionSelector'
  },
  optionsPanel: {
    map: [{
      component: 'listSelector',
      id: 'chill-units-regions',
      useSharedState: 'regionSelector'
    },{
      component: 'departureSelector',
      id: 'chill-units-comparison',
      useSharedState: 'departureSelector'
    }],
    chart: [{
      component: 'listSelector',
      id: 'chill-units-charts-selector',
      useSharedState: 'chillUnitsChartSelector',
      props: {
        listTypeSingular: 'Chart',
        listTypePlural: 'Charts',
        showAll: false,
        showNone: false
      }
    },{
      component: 'thresholdSelector',
      id: 'chill-units-threshold-selector',
      useSharedState: 'chillUnitsThresholdSelector',
      props: {
        columnHeading: 'Chill Unit',
        minNum: 0,
        maxNum: 3,
        minValue: 0,
        maxValue: Infinity,
        step: 100,
        initValue: 1000,
        colors: ['#bae4b3', '#74c476', '#238b45']
      }
    }]
  },
  componentFns: {
    title: ({selectedSubTab, overlayDataDate}) => {
      try {
        const dateObj = parseISO(overlayDataDate);
        let syear = dateObj.getFullYear() - (isBefore(dateObj, new Date(dateObj.getFullYear(), 6, 1)) ? 1 : 0);
        const sdate = format(new Date(syear, 6, 1), 'LLLL do, yyyy');
        const edate = format(dateObj, 'LLLL do, yyyy');
        return `${selectedSubTab} Chill Units: ${sdate} - ${edate}`;
      } catch {
        return '';
      }
    }
  },
  textPanelInfo: [{
    name: 'How To Use Tool',
    markdownFilename: 'how-to-use_chill.md'
  },{
    name: 'FAQ',
    markdownFilename: 'faq_default.md'
  },{
    name: 'About The Data',
    markdownFilename: 'about-the-data_chill.md'
  }]
};
export default info;

// const chartNames = info.chartInfo.charts.map(obj => obj.name);
// export { chartNames as chillUnitsCharts };
const chillUnitsCharts = info.chartInfo.charts;
export { chillUnitsCharts };