import React, { useState, createContext } from 'react';
import PropTypes from 'prop-types';

import useTabInformation from '../hooks/useTabInformation';


// Set up initial state of context
export const TabInfoContext = createContext({
  tabInformation: [],
  tabsSharedState: {},
  navInfo: [],
  componentFns: {},
  textPanelInfo: [],
  chartInfo: {},
  pageInfo: {},
  updateTabFunctions: {},
  updateCurrentTabInformation: () => {},
  getSelectorValueFromTabInformation: () => {}
});

// Set up context provider
export const TabInfoProvider = ({ children }) => {
  const { tabInformation, tabsSharedState, updateTabFunctions, getSelectorValueFromTabInformation } = useTabInformation();

  const [currentTabInformation, setCurrentTabInformation] = useState({});

  function updateCurrentTabInformation(updatedStateObjs) {
    setCurrentTabInformation(updatedStateObjs.tabInformation.find(tab => tab.name === updatedStateObjs.selectedTab) || {});
  }

  const value = {
    tabInformation,
    tabsSharedState,
    navInfo: tabInformation && tabInformation.map(tab => tab.navInfo),
    componentFns: currentTabInformation.componentFns || {},
    textPanelInfo: currentTabInformation.textPanelInfo || [],
    chartInfo: currentTabInformation.chartInfo || {},
    pageInfo: currentTabInformation.pageInfo || {},
    updateTabFunctions,
    updateCurrentTabInformation,
    getSelectorValueFromTabInformation
  };
  return (
    <TabInfoContext.Provider value={value}>{children}</TabInfoContext.Provider>
  );
};

TabInfoProvider.propTypes = {
  children: PropTypes.node,
};
