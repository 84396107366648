import { parseISO, subYears, addDays, format } from 'date-fns';

import calcTimeseriesOptions from "../../utilities/charts/timeseries-options";
import calcPestTimeseriesOptions from '../../utilities/charts/pest-timeseries-options';
import calcOnTodayValueAmountOptions from "../../utilities/charts/onToday-value-amount-options";
import calcThresholdsOptions from "../../utilities/charts/thresholds-options";
import updateOverlayFromR2 from '../../utilities/overlayUpdateFns';
import { refreshGddChartData } from '../../utilities/charts/chart-data-functions';
import { fetchFromAcis } from '../../utilities/acis-api';
import { TODAY } from '../../contexts/constants';

const addName = (chartData, address, tabInfoContextState, fn) => {
  const { tabsSharedState } = tabInfoContextState;
  const base = tabsSharedState.gddBaseSelector.value[0];
  return fn(chartData, address, `Base ${base} GDDs`);
};

const PEST_PRESETS = {
  'Bug 1': { base: 53, emergenceAtGdds: 500 },
  'Bug 2': { base: 52, emergenceAtGdds: 1000 },
};

const handleGddStartDateChange = (oldValue, { newSubTab, newId, newPastLocations }, { selectedSubTab, pastLocations, selectedLocation }) => {
  const subTab = newSubTab || selectedSubTab;
  if (subTab.includes('Bug')) {
    const id = newId || selectedLocation;
    const locs = newPastLocations || pastLocations
    const locationInfo = locs[id];
    const year = oldValue.slice(0,4);
    let day = oldValue.slice(5,10);
    if (locationInfo.lat < 25) day = '01-01';
    else if (locationInfo.lat < 30) day = '02-01';
    else if (locationInfo.lat < 35) day = '03-01';
    else if (locationInfo.lat < 40) day = '04-01';
    else if (locationInfo.lat < 45) day = '05-01';
    else if (locationInfo.lat < 50) day = '06-01';
    return year + '-' + day;
  }
  return oldValue;
}

const TAB_NAME = 'GDDs';
const info = {
  name: TAB_NAME,
  subTabs: {
    'no-subheader-bases-group': [{ 
        name: 'Base 50',
      }, { 
        name: 'Base 32',
      }
    ],
    'Pest Presets': Object.keys(PEST_PRESETS).map(name => ({ name }))
  },
  chartInfo: {
    chartSelectorId: 'gddChartSelector',
    charts: [{
      name: 'GDD Accumulation Timeseries',
      showIfSubTab: ['Base 50', 'Base 32'],
      func: (chartData, address, tabInfoContextState) => addName(chartData, address, tabInfoContextState, calcTimeseriesOptions)
    },{
      name: 'GDD Accumulation Timeseries with Bug 1 Emergence',
      showIfSubTab: ['Bug 1'],
      func: (chartData, address, tabInfoContextState) => addName({ pest: 'Bug 1', ...PEST_PRESETS['Bug 1'], ...chartData}, address, tabInfoContextState, calcPestTimeseriesOptions)
    },{
      name: 'GDD Accumulation Timeseries with Bug 2 Emergence',
      showIfSubTab: ['Bug 2'],
      func: (chartData, address, tabInfoContextState) => addName({ pest: 'Bug 2', ...PEST_PRESETS['Bug 2'], ...chartData}, address, tabInfoContextState, calcPestTimeseriesOptions)
    },{
      name: 'GDDs Achieved On This Date',
      func: (chartData, address, tabInfoContextState) => addName(chartData, address, tabInfoContextState, calcOnTodayValueAmountOptions)
    },{
      name: 'Date of Threshold Exceedance',
      func: (chartData, address, tabInfoContextState) => addName(chartData, address, tabInfoContextState, calcThresholdsOptions)
    }],
  },
  onChange: [{
    whenXChanges: ['selectedSubTab'],
    handleChange: ({ selectedTab, selectedSubTab, overlayFilename }, _, { updateOverlayData }) => {
      if (selectedTab === TAB_NAME) {
        let jsonFileName;
        if (selectedSubTab.includes('Base')) {
          jsonFileName = `${selectedSubTab.split(' ').join('_').toLowerCase()}.json`;
        } else if (overlayFilename) {
          return () => {};
        } else {
          jsonFileName = 'base_50.json';
        }
        const jsonFileDataKey = 'gdd_departures';
        return updateOverlayFromR2(jsonFileName, jsonFileDataKey, undefined, true)
          .then(d => () => updateOverlayData(d));
      }
      return () => {};
    },
    showLoading: true
  },{
    whenXChanges: ['selectedLocation', 'selectedTab', 'selectedSubTab', 'gddBaseSelector'],
    handleChange: ({ selectedTab, selectedLocation, pastLocations, overlayDataDate, rawChartData, tabsSharedState }, _, { setRawChartData }) => {
      if (selectedTab === TAB_NAME) {
        // Make a copy of the existing chart data
        let newRawChartData = { ...rawChartData };
        
        // If the location has changed forget all previous data
        if (!(selectedLocation in newRawChartData)) {
          newRawChartData = { [selectedLocation]: {} };
        }
        
        // If we do not already have data for the new GDD base then fetch it
        const baseNumber = tabsSharedState.gddBaseSelector.value[0];
        const targetBase = `gddBase${baseNumber}`;
        if (!(targetBase in newRawChartData[selectedLocation])) {
          const locInfo = pastLocations[selectedLocation];
          const coords = `${locInfo.lng},${locInfo.lat}`;
          const dateObj = overlayDataDate ? parseISO(overlayDataDate) : TODAY;
          const startYear = dateObj.getFullYear() - 32;

          const elems = {
            loc: coords,
            grid: 'prism',
            sdate: format(dateObj, `${startYear}-MM-dd`),
            edate: format(dateObj, 'yyyy-MM-dd'),
            elems: [{
              name: 'gdd',
              base: baseNumber
            }],
          };

          return fetchFromAcis(elems)
            .then(rawData => {
              newRawChartData[selectedLocation][targetBase] = rawData;
              return () => setRawChartData(newRawChartData);
            });
        }
      }
      
      return () => {};
    },
    showLoading: true
  },{
    whenXChanges: ['gddThresholdSelector', 'gddStartDateSelector', 'rawChartData', 'selectedSubTab'],
    handleChange: ({ selectedTab, selectedLocation, tabsSharedState, rawChartData, overlayDataDate }, _, { setChartData }) => {
      const baseNumber = tabsSharedState.gddBaseSelector.value[0];
      const targetBase = `gddBase${baseNumber}`;
      if (selectedTab === TAB_NAME && selectedLocation in rawChartData && targetBase in rawChartData[selectedLocation]) {
        const { newChartData } = refreshGddChartData(rawChartData[selectedLocation][targetBase], tabsSharedState.gddThresholdSelector.value, tabsSharedState.gddStartDateSelector.value, overlayDataDate ? overlayDataDate : format(TODAY, 'yyyy-MM-dd'));
        return () => setChartData(newChartData);
      }
      return () => {};
    },
    showLoading: true
  }],
  mapInfo: {
    overlayKeySelectorId: 'departureSelector',
    regionsSelectorId: 'regionSelector'
  },
  optionsPanel: {
    map: [{
      component: 'listSelector',
      id: 'gdds-regions',
      useSharedState: 'regionSelector'
    },{
      component: 'departureSelector',
      id: 'gdds-comparison',
      useSharedState: 'departureSelector'
    }],
    chart: [{
      component: 'listSelector',
      id: 'gdds-charts',
      useSharedState: 'gddChartSelector',
      props: {
        listTypeSingular: 'Chart',
        listTypePlural: 'Charts',
        showAll: false,
        showNone: false
      }
    },{
      component: 'thresholdSelector',
      id: 'gdd-thresholds-selector',
      useSharedState: 'gddThresholdSelector',
      props: {
        columnHeading: 'GDD',
        minNum: 0,
        maxNum: 3,
        minValue: 0,
        maxValue: Infinity,
        step: 100,
        initValue: 1000,
        colors: ['#bae4b3', '#74c476', '#238b45']
      }
    },{
      component: 'dateSelector',
      id: 'gdd-sdate-selector',
      useSharedState: 'gddStartDateSelector',
      props: {
        label: 'Season Start Date',
        pickerLabel: 'Only affects GDD charts',
        minDate: (today) => addDays(subYears(parseISO(today), 1), 1),
        maxDate: (today) => parseISO(today)
      }
    },{
      component: 'thresholdSelector',
      id: 'gdd-base-selector',
      useSharedState: 'gddBaseSelector',
      props: {
        columnHeading: 'GDD',
        unit: 'Base',
        minNum: 1,
        maxNum: 1,
        minValue: -200,
        maxValue: 200,
        initValue: 'gddSelectedSubTab',
        colors: ['black'],
        label: (value, unit) => `GDD ${unit} ${value}`,
        automaticUpdateOnEdit: false,
        showReset: true
      }
    }]
  },
  componentFns: {
    title: ({display, overlayDataDate, overlayFilename, tabsSharedState}) => {
      try {
        let sdate, base;
        if (display === 'map') {
          const dateObj = parseISO(overlayDataDate);
          sdate = format(new Date(dateObj.getFullYear(), 0, 1), 'LLLL do, yyyy');
          base = overlayFilename.slice(0,-5).replace('_', ' ');
          base = base[0].toUpperCase() + base.slice(1);
        } else {
          sdate = format(parseISO((tabsSharedState.gddStartDateSelector.value)), 'LLLL do, yyyy');
          base = `Base ${tabsSharedState.gddBaseSelector.value}`;
        }
  
        const edate = format(parseISO(overlayDataDate), 'LLLL do, yyyy');
        return `${base} GDDs: ${sdate} - ${edate}`;
      } catch {
        return '';
      }
    }
  },
  textPanelInfo: [{
    name: 'Pest Description',
    onlyShowIfChart: true,
    onlyShowIfSubTab: ['Bug 1'],
    markdownFilename: 'bug_1.md'
  },{
    name: 'Analysis',
    onlyShowIfChart: true,
    generateMarkdown: ({ chartData,  tabsSharedState }) => {
      try {
        const baseNumber = tabsSharedState.gddBaseSelector.value[0];
        // const targetBase = `gddBase${baseNumber}`;
        
        let sdate = tabsSharedState.gddStartDateSelector.value;
        sdate = sdate.slice(5, 10) + '-' + sdate.slice(0,4);
        const sGdd = 0;
        let edate = chartData.timeseries.dates.slice(-1)[0] + '-' + sdate.slice(-4);
        const eGdd = chartData.timeseries.current[chartData.timeseries.dates.length - 1];
        
        return `#  Success \n The mablib text for GDD Base ${baseNumber} will go here.\n\n The current season started on ${sdate} at ${sGdd} Base ${baseNumber} GDDs and ended on ${edate} at ${eGdd} Base ${baseNumber} GDDs`;
      } catch {
        return '';
      }
    }
  },{
    name: 'Local Links',
    onlyShowIfChart: true,
    generateMarkdown: ({ selectedLocation }) => {
      return `${selectedLocation.lng}, ${selectedLocation.lat} links: \n - [Duck Duck Go](https://duckduckgo.com) \n - [Google](https://google.com)`;
    }
  },{
    name: 'FAQ',
    markdownFilename: 'faq_default.md'
  }]
};
export default info;

// const chartNames = info.chartInfo.charts.map(obj => obj.name);
// export { chartNames as gddCharts };
const gddCharts = info.chartInfo.charts
export { gddCharts, PEST_PRESETS as pestPresets, handleGddStartDateChange };