import React, { useContext } from 'react';

import { GlobalContext } from '../../contexts/global.context';
import { TabInfoContext } from '../../contexts/tab-info.context';

import './data-date-type-title.styles.scss';

export default function DataDateTypeTitle() {
  const { display, selectedSubTab, overlayDataDate, overlayFilename } = useContext(GlobalContext);
  const { tabsSharedState, componentFns } = useContext(TabInfoContext);

  const title = (componentFns && 'title' in componentFns) ? componentFns.title({ selectedSubTab, display, overlayDataDate, overlayFilename, tabsSharedState }) : '';

  return <div className='data-date-type-container'>
    <h2 className='data-date-type-text'>{title}</h2>
  </div>;
}