import { parseISO, subYears, addDays, format } from 'date-fns';

import { calcDailyPrecipOptions, calcAnnualPrecipOptions } from '../../utilities/charts/precip-chart-options';
import updateOverlayFromR2 from '../../utilities/overlayUpdateFns';
import { fetchFromAcis } from '../../utilities/acis-api';
import { TODAY } from '../../contexts/constants';

const refreshHeavyRainChartData = (pcpns, threshold, sdate, edate) => {
  const newChartData = {
    timeseries: {
      dates: [],
      current: []
    },
    yearlySeasons: {
      years: [],
      toDate: [],
      fullSeason: []
    }
  };

  const seasonStartDay = sdate ? sdate.slice(5,10) : '01-01';
  const seasonEndDay = edate ? edate.slice(5,10) : pcpns[pcpns.length - 1][0].slice(5);

  let idxYearStart = null;
  for (let i = 0; i < pcpns.length; i++) {
    const strDate = pcpns[i][0];
    
    if (strDate.slice(5) === seasonEndDay && idxYearStart !== null) {
      newChartData.yearlySeasons.toDate.push(pcpns.slice(idxYearStart, i).filter(([_, val]) => val >= threshold).length);
    }

    if (strDate.slice(5) === seasonStartDay) {
      if (idxYearStart !== null) {
        const seasonData = pcpns.slice(idxYearStart, i);
        newChartData.yearlySeasons.years.push(parseInt(seasonData[seasonData.length - 1][0].slice(0,4)));
        newChartData.yearlySeasons.fullSeason.push(seasonData.filter(([_, val]) => val >= threshold).length);
      }
      idxYearStart = i;
    }
  }

  let csdEndIdx = pcpns.slice(idxYearStart).findIndex(([date, pcpn]) => date.slice(5) === seasonEndDay);
  if (csdEndIdx === -1) csdEndIdx = pcpns.length - 1;
  
  const currentSeasonData = pcpns.slice(idxYearStart, idxYearStart + csdEndIdx + 1);
  newChartData.yearlySeasons.years.push(parseInt(currentSeasonData[currentSeasonData.length - 1][0].slice(0,4)));
  newChartData.yearlySeasons.fullSeason.push(currentSeasonData.filter(([_, val]) => val >= threshold).length);
  newChartData.timeseries = currentSeasonData.reduce((acc, [date, value]) => {
    acc.dates.push(date);
    acc.current.push(value);
    return acc;
  }, { dates: [], current: [] });

  newChartData.yearlySeasons.fullSeason.slice(-31);
  newChartData.yearlySeasons.toDate.slice(-31);
  newChartData.yearlySeasons.years.slice(-31);

  return { newChartData, newCurrentSeasonChartData: null };
};


const TAB_NAME = 'Heavy Rain';
const info = {
  name: TAB_NAME,
  subTabs: [{
    name: '>0.5 inches'
  },{
    name: '>1.0 inches'
  }],
  chartInfo: {
    chartSelectorId: 'heavyRainChartSelector',
    charts: [{
      name: 'Daily Precipitation and Threshold Exceedance',
      func: (chartData, address, tabInfoContextState) => calcDailyPrecipOptions(chartData, address, tabInfoContextState.tabsSharedState.heavyRainThresholdSelector.value[0])
    },{
      name: 'Annual Number of Occurences',
      func: calcAnnualPrecipOptions
    }],
  },
  onChange: [{
    whenXChanges: ['selectedSubTab'],
    handleChange: ({ selectedSubTab }, _, { updateOverlayData }) => {
      const jsonFileName = `${selectedSubTab.slice(1,4)}_inches.json`;
      const jsonFileDataKey = 'pcpn_departures';
      return updateOverlayFromR2(jsonFileName, jsonFileDataKey)
        .then(d => () => updateOverlayData(d));
    },
    showLoading: true
  },{
    whenXChanges: ['selectedLocation', 'selectedTab'],
    handleChange: ({ selectedTab, selectedLocation, pastLocations, overlayDataDate, rawChartData }, _, { setRawChartData }) => {
      if (selectedTab === TAB_NAME) {
        let newRawChartData = { ...rawChartData };
        if (!(selectedLocation in newRawChartData)) {
          newRawChartData = { [selectedLocation]: {} };
        }
        
        if (!(selectedTab in newRawChartData[selectedLocation])) {
          const locInfo = pastLocations[selectedLocation];
          const coords = `${locInfo.lng},${locInfo.lat}`;
          const dateObj = overlayDataDate ? parseISO(overlayDataDate) : TODAY;
          const startYear = dateObj.getFullYear() - 32;

          const elems = {
            loc: coords,
            grid: 'prism',
            sdate: format(dateObj, `${startYear}-MM-dd`),
            edate: format(dateObj, 'yyyy-MM-dd'),
            elems: [{
              name: 'pcpn'
            }]
          };

          return fetchFromAcis(elems)
            .then(rawData => {
              newRawChartData[selectedLocation][selectedTab] = rawData;
              return () => setRawChartData(newRawChartData);
            });
          }
      }
      
      return () => {};
    },
    showLoading: true
  },{
    whenXChanges: ['heavyRainThresholdSelector', 'heavyRainStartDateSelector', 'rawChartData', 'selectedSubTab'],
    handleChange: ({ selectedTab, selectedLocation, tabsSharedState, rawChartData, overlayDataDate }, _, { setChartData }) => {
      if (selectedTab === TAB_NAME && selectedLocation in rawChartData && selectedTab in rawChartData[selectedLocation]) {
        const { newChartData } = refreshHeavyRainChartData(rawChartData[selectedLocation][selectedTab], tabsSharedState.heavyRainThresholdSelector.value, tabsSharedState.heavyRainStartDateSelector.value, overlayDataDate);
        return () => setChartData(newChartData);
      }
      return () => {};
    },
    showLoading: true
  }],
  mapInfo: {
    overlayKeySelectorId: 'departureSelector',
    regionsSelectorId: 'regionSelector'
  },
  optionsPanel: {
    map: [{
      component: 'listSelector',
      id: 'heavy-rain-regions',
      useSharedState: 'regionSelector'
    },{
      component: 'departureSelector',
      id: 'heavy-rain-comparison',
      useSharedState: 'departureSelector'
    }],
    chart: [{
      component: 'listSelector',
      id: 'heavy-rain-charts',
      useSharedState: 'heavyRainChartSelector',
      props: {
        listTypeSingular: 'Chart',
        listTypePlural: 'Charts',
        showAll: false,
        showNone: false
      }
    },{
      component: 'thresholdSelector',
      id: 'heavy-rain-threshold-selector',
      useSharedState: 'heavyRainThresholdSelector',
      props: {
        columnHeading: 'Heavy Rain',
        minNum: 1,
        maxNum: 1,
        minValue: 0,
        maxValue: 100,
        step: 0.1,
        colors: ['black'],
        label: () => 'Change Threshold',
        showReset: true
      }
    },{
      component: 'dateSelector',
      id: 'heavy-rain-sdate-selector',
      useSharedState: 'heavyRainStartDateSelector',
      props: {
        label: 'Season Start Date',
        pickerLabel: 'Only affects Heavy Rain charts',
        minDate: (today) => addDays(subYears(parseISO(today), 1), 1),
        maxDate: (today) => parseISO(today)
      }
    }]
  },
  componentFns: {
    title: ({selectedSubTab, display, overlayDataDate, tabsSharedState}) => {
      try {
        let sdate;
        if (display === 'map') {
          const dateObj = parseISO(overlayDataDate);
          sdate = format(new Date(dateObj.getFullYear(), 0, 1), 'LLLL do, yyyy');
        } else {
          sdate = format(parseISO(tabsSharedState.heavyRainStartDateSelector.value), 'LLLL do, yyyy');
        }
        
        const edate = format(parseISO(overlayDataDate), 'LLLL do, yyyy');
        return `${selectedSubTab} Precipitation: ${sdate} - ${edate}`;
      } catch {
        return '';
      }
    }
  },
  textPanelInfo: []
};
export default info;

// const chartNames = info.chartInfo.charts.map(obj => obj.name);
// export { chartNames as heavyRainCharts };
const heavyRainCharts = info.chartInfo.charts;
export { heavyRainCharts };