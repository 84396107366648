import React, {
  createContext,
  useState,
  useContext,
  useRef,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';

import { regionBBoxes } from './constants';

import { GlobalContext } from './global.context';

// Bounding box used to set initial view
const INIT_BOUNDS = [-126, 34, -66, 43];

// Bounding box used to limit panning
const BBOX = {
  north: 53.3,
  south: 19.8,
  east: -54.0,
  west: -137.8,
};

// Clamps latitude
function isOutsideLatBounds(lat) {
  return lat > BBOX.north || lat < BBOX.south;
}

// Clamps longitude
function isOutsideLngBounds(lng) {
  return lng > BBOX.east || lng < BBOX.west;
}

// Gets the address name for a set of coordinates if it is within the list of allowed states
function getLocation(lat, lng, token) {
  if (isOutsideLatBounds(lat) || isOutsideLngBounds(lng)) {
    return false;
  }

  return fetch(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?limit=1&access_token=${token}`,
    { method: 'GET' }
  )
    .then((response) => response.json())
    .then((res) => {
      const feature = res.features[0];
      const countryCode =
        feature.context[feature.context.length - 1].short_code;
      if (countryCode !== 'us') {
        throw new Error('Out of bounds');
      }

      // Construct user friendly address
      const address = feature.place_name
        .replace(', United States', '')
        .replace(/\s\d{5}/g, '');

      return {
        id: String(Date.now()),
        address,
        lat,
        lng,
      };
    })
    .catch(() => false);
}

// Set up initial state of context
export const MapContext = createContext({
  mapRef: null,
  viewState: {},
  handleLoad: () => null,
  handleMapClick: () => null,
  handleZoomToBbox: () => null,
  handlePanning: () => null,
  resetViewState: () => null,
  isInitView: true,
});

// Set up context provider
export const MapProvider = ({ children }) => {
  const mapRef = useRef(null);
  const [viewState, setViewState] = useState({
    bounds: INIT_BOUNDS,
  });
  const [initView, setInitView] = useState(null);
  const [isInitView, setIsInitView] = useState(true);

  const { pastLocations, handleUpdate } = useContext(GlobalContext);

  useEffect(() => {
    try {
      const currCenter = mapRef.current.getCenter();
      const currZoom = mapRef.current.getZoom();

      setIsInitView(
        currCenter.lat === initView.center[1] &&
          currCenter.lng === initView.center[0] &&
          currZoom === initView.zoom
      );
    } catch {
      setIsInitView(true);
    }
  }, [viewState, initView]);

  // Tries to get new location information, stores it if successful, moves map to new location
  const handleMapClick = async (e) => {
    const results = await getLocation(e.lngLat.lat, e.lngLat.lng, process.env.REACT_APP_MAPBOX_TOKEN);
    if (results) {
      const newPastLocs = {
        ...pastLocations,
        [results.id]: results,
      };

      handleUpdate('addLocation', { newId: results.id, newPastLocations: newPastLocs });

      if (mapRef.current) {
        mapRef.current.flyTo({
          center: [e.lngLat.lng, e.lngLat.lat],
          speed: 0.8,
          essential: true,
        });
      }
    }
  };

  // Tries to get new location information, stores it if successful, moves map to new location
  const handleZoomToBbox = async (regions) => {
    if (mapRef.current) {
      if (regions.length === 0 || regions.length === Object.keys(regionBBoxes).length) {
        resetViewState();
      } else {
        const bbox = regions.reduce((acc, regName) => {
          const bbox = regionBBoxes[regName];
          if (bbox[0][0] < acc[0][0]) acc[0][0] = bbox[0][0];
          if (bbox[0][1] < acc[0][1]) acc[0][1] = bbox[0][1];
          if (bbox[1][0] > acc[1][0]) acc[1][0] = bbox[1][0];
          if (bbox[1][1] > acc[1][1]) acc[1][1] = bbox[1][1];
          return acc;
        }, [[180,90],[-180,-90]]);
        mapRef.current.fitBounds(bbox, { padding: 30 });
      }
    }
  };

  // Limits panning to bbox coordinates
  const handlePanning = (view) => {
    if (isOutsideLatBounds(view.latitude)) {
      view.latitude = viewState.latitude;
    }

    if (isOutsideLngBounds(view.longitude)) {
      view.longitude = viewState.longitude;
    }

    setViewState((prev) => {
      return {
        ...prev,
        ...view,
      };
    });
  };

  // Increases zoom step and sets init zoom for reset button to use
  const handleLoad = (e) => {
    e.target.scrollZoom.setWheelZoomRate(1 / 100);

    const center = e.target.getCenter();
    setInitView({
      zoom: e.target.getZoom(),
      center: [center.lng, center.lat],
    });
  };

  // Moves map to initial view
  const resetViewState = () => {
    mapRef.current.flyTo({
      ...initView,
      speed: 0.8,
      essential: true,
    });
  };

  const value = {
    mapRef,
    viewState,
    handleLoad,
    handleMapClick,
    handleZoomToBbox,
    handlePanning,
    resetViewState,
    isInitView,
  };
  return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
};

MapProvider.propTypes = {
  children: PropTypes.node,
};
