import React, { useContext } from 'react';
import {
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@mui/material';

import Accordion from '../accordion/accordion.component';

import { GlobalContext } from '../../contexts/global.context';

const defaultProps = {
  options: [],
  showAll: true,
  showNone: true,
  value: [],
  listTypeSingular: 'Item',
  listTypePlural: 'Items'
};

export default function ListSelector({
  expanded,
  handleChange,
  handleChangeSelection,
  id=null,
  options=defaultProps.options,
  showAll=defaultProps.showAll,
  showNone=defaultProps.showNone,
  value=defaultProps.value,
  listTypeSingular=defaultProps.listTypeSingular,
  listTypePlural=defaultProps.listTypePlural,
}) {
  const { selectedSubTab } = useContext(GlobalContext);

  if (options.length === 0) {
    return <div>No options provided to selector.</div>;
  } else if (id === null) {
    return <div>Must provide id to component.</div>;
  }

  const showableValueNames = value.filter(v => Array.isArray(v.showIfSubTab) ? v.showIfSubTab.includes(selectedSubTab) : true).map(v => Array.isArray(v) ? v.name : v);
  const showableOptions = options.filter(v => Array.isArray(v.showIfSubTab) ? v.showIfSubTab.includes(selectedSubTab) : true);

  const label = showableValueNames.length === 0 ? `Select ${listTypePlural}` : `Showing ${showableValueNames.length} ${showableValueNames.length === 1 ? listTypeSingular : listTypePlural}`;

  return (
    <Accordion expanded={expanded === id} handleChange={handleChange} id={id} label={label} >
      <div className='list-selector-container'>
        <div className='list-selector-title'>Select {listTypePlural}</div>
        <FormGroup>
          {showAll ?
            <FormControlLabel
              control={
                <Checkbox
                  checked={showableValueNames.length === showableOptions.length}
                  onChange={() => handleChangeSelection(showableOptions)}
                />
              }
              label='All'
            />
            :
            ''
          }

          {showableOptions.map((opt, i) => {
            const name = opt.constructor.name === 'Object' ? opt.name : opt;
            const isSelected = Boolean(showableValueNames.find(svn => (svn.constructor.name === 'Object' ? svn.name : svn) === name));

            return <FormControlLabel
              key={name + i}
              control={
                <Checkbox
                  checked={isSelected}
                  onChange={() => {
                    if (isSelected) {
                      handleChangeSelection(value.filter((r) => (r.constructor.name === 'Object' ? r.name : r) !== name));
                    } else {
                      handleChangeSelection([...value, opt]);
                    }
                    
                  }}
                />
              }
              label={name}
            />;
          })}

          {showNone ?
            <FormControlLabel
              control={
                <Checkbox
                  checked={showableValueNames.length === 0}
                  onChange={() => handleChangeSelection([])}
                />
              }
              label='None'
            />
            :
            ''
          }
        </FormGroup>
      </div>
    </Accordion>
  );
}

export { defaultProps };