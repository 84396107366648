import React, { useEffect, useState } from 'react';

import Button from '../../button/button.component';
import Accordion from '../../accordion/accordion.component';
import ThresholdItem from './threshold-item/threshold-item.component';

import './threshold-selector.styles.scss';

import checkIfArraysContainSameValues from '../../../utilities/compareArrays';

const defaultProps = {
  value: [],
  columnHeading: '',
  minNum: 0,
  maxNum: 1,
  minValue: 0,
  maxValue: Infinity,
  step: 1,
  initValue: 1,
  resetValue: 1,
  colors: [],
  showReset: false,
  automaticUpdateOnEdit: true,
  unit: 'Threshold',
  label: (currValue) => currValue.length ? `Edit Threshold${currValue.length > 1 ? `s (${currValue.length})` : ''}` : 'Set Thresholds'
};

export default function ThresholdSelector({
  id,
  expanded,
  handleChange,
  handleChangeSelection,
  value=defaultProps.value,
  columnHeading=defaultProps.columnHeading,
  minNum=defaultProps.minNum,
  maxNum=defaultProps.maxNum,
  minValue=defaultProps.minValue,
  maxValue=defaultProps.maxValue,
  step=defaultProps.step,
  initValue=defaultProps.initValue,
  colors=defaultProps.colors,
  showReset=defaultProps.showReset,
  resetValue=defaultProps.resetValue,
  automaticUpdateOnEdit=defaultProps.automaticUpdateOnEdit,
  unit=defaultProps.unit,
  label=defaultProps.label
}) {
  const [shownThreshold, setShownThreshold] = useState([...value]);

  useEffect(() => {
    if (!checkIfArraysContainSameValues(shownThreshold, value)) {
      setShownThreshold([...value]);
    }
  }, [value]);
  
  useEffect(() => {
    if (minNum > value.length) {
      const newValue = [...value];
      while (newValue.length < minNum) {
        newValue.push(initValue);
      }
      setShownThreshold(newValue);
      handleChangeSelection(newValue);
    }
  }, [minNum, initValue]);

  const thresholdCount = value.length;
  const sortedThresholds = [...shownThreshold].sort((a, b) => b - a);
  const canSubmit = !checkIfArraysContainSameValues(shownThreshold, value);
  const canReset = showReset && shownThreshold.some(v => v !== resetValue);
  const removable = (thresholdCount > minNum && minNum !== maxNum)

  const addThreshold = () => {
    if (thresholdCount < maxNum) {
      const newValue  = [...value, initValue];
      setShownThreshold(newValue);
      handleChangeSelection(newValue);
    }
  };

  const editShownThreshold = (indexToEdit, newValue) => {
    setShownThreshold(shownThreshold.map((value, i) => (i === indexToEdit ? newValue : value)));
  };

  const resetShownThreshold = () => {
    const newValue = shownThreshold.map(_ => resetValue);

    setShownThreshold(newValue);
    handleChangeSelection(newValue);
  };

  const submitEdits = () => {
    handleChangeSelection([...shownThreshold]);
  };
  
  const editThreshold = (indexToEdit, newValue) => {
    const editedValue = shownThreshold.map((value, i) => (i === indexToEdit ? newValue : value));
    setShownThreshold(editedValue);
    handleChangeSelection(editedValue);
  };

  const removeThreshold = (indexToRemove) => {
    if (thresholdCount > minNum) {
      const newValue = [ ...value.slice(0, indexToRemove), ...value.slice(indexToRemove + 1) ];
      setShownThreshold(newValue);
      handleChangeSelection(newValue);
    }
  };

  return (
    <Accordion
      expanded={expanded === id}
      handleChange={handleChange}
      id={id}
      label={label(value, unit)}
    >
      <div className='threshold-selector-content-container'>
        {!!thresholdCount && (
          <div className='header-container'>
            <div className='header-value'>{columnHeading} {unit}</div>
            <div className='header-controls-container'>
              <div className='header-minus'>&#8722;</div>
              {removable ? <div className='header-remove'>Remove</div> : ''}
              <div className='header-add'>+</div>
            </div>
          </div>
        )}

        {thresholdCount ? (
          shownThreshold.map((threshold, i) => (
            <ThresholdItem
              key={i}
              index={i}
              value={threshold}
              color={(minNum === 1 && maxNum === 1) ? 'black' : colors[sortedThresholds.indexOf(threshold)]}
              editThreshold={automaticUpdateOnEdit ? editThreshold : editShownThreshold}
              removeThreshold={removeThreshold}
              step={step}
              min={minValue}
              max={maxValue}
              removable={removable}
            />
          ))
        ) : (
          <div className='no-thresholds'>No {unit}s created</div>
        )}

        {(thresholdCount < maxNum || canReset || !automaticUpdateOnEdit) &&
          <div className='add-button-container'>
            {thresholdCount < maxNum && (
              <Button
                buttonType='centeredSmall'
                onClick={addThreshold}
              >Add {unit}</Button>
            )}

            {canReset && (
              <Button
                buttonType='centeredSmall'
                onClick={resetShownThreshold}
                style={{
                  margin: '12px auto'
                }}
              >Reset {unit}</Button>
            )}

            {!automaticUpdateOnEdit && (
              <Button
                buttonType={canSubmit ? 'centeredSmall' : 'disabled'}
                onClick={submitEdits}
                disabled={!canSubmit}
                style={{
                  margin: '12px auto'
                }}
              >Submit</Button>
            )}
          </div>
        }
      </div>
    </Accordion>
  );
}

export { defaultProps };