import { useState } from "react";

// Gets persisted data item
function getStoredItem(itemName, initValue, parseIt=false) {
  try {
    const storedValue = localStorage.getItem(itemName) || null;
    if (storedValue === null) {
      return initValue;
    } else {
      return (parseIt && storedValue) ? JSON.parse(storedValue) : storedValue;
    }
  } catch {
    if (window.confirm('An error occurred trying to load you stored data. Please click "OK" to clear the data that may be causing this issue. NOTE: You may lose some of your stored selections.')) {
      localStorage.removeItem(itemName);
      return null;
    }
  }
}

export default function useStoredState(itemName, initValue, parseIt=false) {
  const [stateValue, setStateValue] = useState(getStoredItem(itemName, initValue, parseIt));

  function updateStoredState(newValue) {
    localStorage.setItem(itemName, parseIt ? JSON.stringify(newValue) : newValue);
    setStateValue(newValue);
  } 

  return [stateValue, updateStoredState];
}

export { getStoredItem };