import { TODAY, regionNames } from "../../contexts/constants";

import { chillUnitsCharts } from "./chill";
import { gddCharts, pestPresets, handleGddStartDateChange } from "./gdds";
import { heavyRainCharts } from "./heavy-rain";

const sharedStateDefinitions = {
  'regionSelector': {
    options: regionNames,
    initValue: regionNames,
    props: {
      listTypeSingular: 'Region',
      listTypePlural: 'Regions',
    }
  },
  'departureSelector': {
    options: ['Last Year', 'Normal'],
    initValue: 'Last Year'
  },
  'chillUnitsChartSelector': {
    options: chillUnitsCharts,
    initValue: chillUnitsCharts
  },
  'chillUnitsThresholdSelector': {
    initValue: []
  },
  'gddChartSelector': {
    options: gddCharts,
    initValue: gddCharts
  },
  'gddThresholdSelector': {
    initValue: []
  },
  'gddStartDateSelector': {
    persist: false,
    initValue: `${TODAY.getFullYear()}-01-01`,
    onNavChange: handleGddStartDateChange,
    onLocationChange: handleGddStartDateChange,
    onInitialize: handleGddStartDateChange
  },
  'gddBaseSelector': {
    onNavChange: (oldValue, { newSubTab }) => {
      let newValue;
      if (newSubTab in pestPresets) {
        newValue = pestPresets[newSubTab].base;
      } else {
        newValue = parseInt(newSubTab.slice(5));
      }
      return isNaN(newValue) ? oldValue : [newValue];
    },
    initValue: ({ selectedSubTab }) => ([parseInt(selectedSubTab.slice(5))])
  },
  'heavyRainChartSelector': {
    options: heavyRainCharts,
    initValue: heavyRainCharts
  },
  'heavyRainThresholdSelector': {
    onNavChange: (oldValue, { newSubTab }) => {
      const newValue = parseFloat(newSubTab.slice(1,4));
      return isNaN(newValue) ? oldValue : [newValue];
    },
    initValue: ({ selectedSubTab }) => ([parseFloat(selectedSubTab.slice(1,4))])
  },
  'heavyRainStartDateSelector': {
    persist: false,
    initValue: `${TODAY.getFullYear()}-01-01`
  },
};

export default sharedStateDefinitions;