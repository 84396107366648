import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { TextField } from '@mui/material';

import { ReactComponent as XIcon } from './closeIcon.svg';

import './threshold-item.styles.scss';

// eslint-disable-next-line no-useless-escape
const decimalRegex = new RegExp('^[+-]?(([1-9][0-9]*)?[0-9](\.[0-9]*)?|\.[0-9]+)$');

export default function ThresholdItem({ value, index=null, color, editThreshold, removeThreshold=()=>{}, removable=true, step=100, min=0, max=Infinity }) {
  // This state along with the two useEffects handle debouncing the ThresholdItem when the user types/increments/decrements
  const [tValue, setTValue] = useState(value);

  useEffect(() => {
    setTValue(value);
  }, [value]);

  useEffect(() => {
      const setNewValue = setTimeout(() => {
        // Coerce to string
        const tValueStr = String(tValue);

        // Check if new value is NaN or an improper number
        if (Number.isNaN(tValueStr) || !decimalRegex.test(tValueStr)) {
          setTValue(value);
        } else if (tValue !== value) {
          // If the new value is different from the current one, parse it to float or integer
          const tValueNum = tValueStr.includes('.') ? parseFloat(tValueStr) : parseInt(tValueStr);

          // Clamp the new value to min and max, update the threshold
          if (tValueNum < min) {
            setTValue(min);
          } else if (tValueNum > max) {
            setTValue(max);
          } else {
            editThreshold(index, tValueNum);
          }
        }
      }, 1000);
      return () => clearTimeout(setNewValue)
  }, [tValue]);
  
  const handleIncrementThreshold = () => {
    const newValue = Math.round((value + step) * 10) / 10;
    if (newValue <= max) {
      editThreshold(index, newValue);
    }
  };

  const handleDecrementThreshold = () => {
    const newValue = Math.round((value - step) * 10) / 10;
    if (newValue >= min) {
      editThreshold(index, newValue);
    }
  };

  const handleRemoveThreshold = () => {
    removeThreshold(index);
  };

  return (
    <div className='threshold-item-container'>
      <TextField
        variant='standard'
        value={tValue}
        onChange={(e) => setTValue(e.target.value)}
        className='threshold-item-value'
        inputProps={{ style: { textAlign: 'center' } }}
      />

      <div className='threshold-item-controls' style={{ color, userSelect: 'none' }}>
        <div className='arrow-container' onClick={handleDecrementThreshold}>
          <span className='arrow'>&#10094;</span>
        </div>
        {removable ?
          <div className='remove-container' onClick={handleRemoveThreshold}>
            <XIcon className='remove' fill={color} />
          </div>
          : ''
        }
        <div className='arrow-container' onClick={handleIncrementThreshold}>
          <span className='arrow'>&#10095;</span>
        </div>
      </div>
    </div>
  );
}

ThresholdItem.propTypes = {
  value: PropTypes.number,
  index: PropTypes.number,
  color: PropTypes.string,
};
