import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import TooltipSeason from '../../components/tooltip-season/tooltip-season.component';
import TooltipThresholds from '../../components/tooltip-thresholds/tooltip-thresholds.component';

const colors = ['#bae4b3', '#74c476', '#238b45'];

export default function calcThresholdsOptions(chartData, address, dataType) {
  let min = Infinity;
  let max = 0;

  const series = chartData.thresholds
    .map((t, i) => {
      const tmin = Math.min(...t.dayOfSeason.filter((val) => val));
      const tmax = Math.max(...t.dayOfSeason);

      if (tmin < min) min = tmin;
      if (tmax > max) max = tmax;

      const data = t.dayOfSeason.map((val) => {
        const obj = {
          y: val,
          color: colors[i],
        };

        if (val === tmin) {
          obj.color = 'blue';
        } else if (val === tmax) {
          obj.color = 'red';
        }

        return obj;
      });

      return {
        data,
        name: `${t.threshold} ${dataType} Threshold`,
        color: colors[i],
      };
    })
    .reverse();

  return {
    chart: {
      marginRight: 20,
    },
    lang: {
      noData: 'No thresholds are defined',
    },
    title: {
      text: 'Annual Date of Threshold Exceedance',
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series,
    xAxis: {
      categories: chartData.years,
      crosshair: {
        color: 'rgb(220,220,220)',
        width: 0.5,
      },
      title: {
        text: 'Season End Year',
      },
    },
    yAxis: {
      gridLineWidth: 0,
      alignTicks: false,
      tickInterval: 10 * 24 * 3600 * 1000,
      type: 'datetime',
      min,
      max,
      title: {
        text: 'Date Occurred',
        style: {
          color: 'rgb(0,150,0)',
        },
      },
      labels: {
        step: 4,
        format: '{value:%b}',
        style: {
          fontFamily:
            'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
        },
      },
      plotLines: [
        {
          color: 'rgb(200,200,200)',
          label: {
            text: `New Year's Day`,
            style: {
              color: 'rgb(150,150,150)',
            },
          },
          value: Date.parse('2021-01-01'),
        },
      ],
    },
    tooltip: {
      shared: true,
      outside: true,
      useHTML: true,
      backgroundColor: 'white',
      formatter: function () {
        if (!this || !this.points) return '';

        const year = this.points[0].x;
        const yearIdx = this.points[0].point.x;

        return renderToStaticMarkup(
          <div>
            <TooltipSeason year={year} color={colors[1]} />
            <TooltipThresholds
              thresholds={chartData.thresholds}
              yearIdx={yearIdx}
            />
          </div>
        );
      },
    },
  };
}
