import React, { useContext } from 'react';

import { GlobalContext } from './contexts/global.context';

import Header from './components/header/header.component';
import Nav from './components/nav/nav.component';
import Footer from './components/footer/footer.component';
import Map from './components/map/map.component';
import Charts from './components/charts/charts.component';
import OptionsPanel from './components/options-panel/options-panel.component';
import Loading from './components/loading/loading.component';
import Legend from './components/legend/legend.component';
import DataDateTypeTitle from './components/data-date-type-title/data-date-type-title.component';
import TextPanel from './components/text-panel/text-panel.component';

import './App.scss';

function App() {
  const { display, isLoading } = useContext(GlobalContext);

  return (
    <div className='App'>
      <div>
        <Header />
        <Nav />
      </div>

      <main>
        <DataDateTypeTitle />

        <div className='map-content-container'>
          <OptionsPanel />
          <div className='main-panel'>
            <Map />
            <Legend />
            {display === 'chart' && <Charts />}
          </div>
        </div>

        <TextPanel />

        {isLoading && <Loading />}
      </main>

      <Footer />
    </div>
  );
}

export default App;
