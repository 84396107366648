import {
  parseISO,
  isSameDay,
  addMonths,
  differenceInDays,
  isLeapYear,
  isBefore,
  addDays,
  format,
  isAfter
} from 'date-fns';
import Spline from 'cubic-spline';

import { fetchFromAcis } from "../acis-api";
import { DynamicChillUnitsModel } from '../chill-models.js';
import roundXDigits from '../round.jsx';

// Used to coerce occurence dates to same year for charting
const FAKE_CHILL_SEASON_START_STR = '2020-09-01';
const FAKE_CHILL_SEASON_START = parseISO(FAKE_CHILL_SEASON_START_STR);

// Creates spline function for temperature interpolation
const calcSpline = (temperatures) => {
  const dates = [];

  // Convert ACIS data into arrays of x values and y values for instantiating Spline
  const xs = Array.from({ length: temperatures.length * 2 }, (v, i) => 12 * i);
  const ys = temperatures
    .map((arr) => {
      dates.push(arr[0]);
      return [arr[1], arr[2]];
    })
    .flat();

  // Create Spline from ACIS data
  const spline = new Spline(xs, ys);

  // Return spline function and dates array
  return {
    spline,
    dates,
  };
};

const parseTemperaturesToSplines = (temperatures, sdate) => {
  let currEndDate = addMonths(parseISO(temperatures[0][0]), 1);
  let yearIdx = 0;
  let monthIdx = 0;

  const datesAndSplines = temperatures.reduce((acc, day) => {
    const date = parseISO(day[0]);
    if (isSameDay(date, currEndDate)) {
      acc[yearIdx][1][monthIdx].push(day);
      acc[yearIdx][1][monthIdx] = calcSpline(acc[yearIdx][1][monthIdx]);
      acc[yearIdx][1][monthIdx].dates.pop();

      currEndDate = addMonths(date, 1);
      monthIdx = (monthIdx + 1) % 12;

      if (date.getMonth() === 6) {
        yearIdx += 1;
      }
    }

    if (!acc[yearIdx]) {
      acc.push([parseInt(sdate.slice(0,4)) + yearIdx + 1, []]);
    }

    if (!acc[yearIdx][1][monthIdx]) {
      acc[yearIdx][1].push([]);
    }

    acc[yearIdx][1][monthIdx].push(day);
    return acc;
  }, []);

  datesAndSplines[yearIdx][1][monthIdx] = calcSpline(
    datesAndSplines[yearIdx][1][monthIdx]
  );

  return datesAndSplines;
};

const getSplines = async (loc, sdate, edate, customElems=null) => {
  const elems = customElems ? customElems : {
    loc,
    grid: 'prism',
    sdate,
    edate,
    elems: [
      {
        name: 'mint',
        interval: [0, 0, 1],
      },
      {
        name: 'maxt',
        interval: [0, 0, 1],
      },
    ],
  };

  const temperatures = await fetchFromAcis(elems);
  const datesAndSplines = parseTemperaturesToSplines(temperatures, sdate);
  return datesAndSplines;
};

const calcRunningAverage = (subsetSize, arr) => {
  const runningAverage = [];
  for (let i = subsetSize; i <= arr.length; i++) {
    let sum = 0;
    let x = i - subsetSize;
    while (x < i) {
      sum += arr[x];
      x++;
    }
    runningAverage.push(roundXDigits(sum / subsetSize, 1));
  }
  return runningAverage;
};

// Calculates chill max date and value for year
const calcChillMax = (
  seasonEDate,
  dates,
  spline,
  thresholds,
  initSum,
  initMax,
  chillModel
) => {
  let ts = [...thresholds];
  let chillMax = [...initMax];
  let onTodayChill = 0;
  let chillSum = initSum;
  const dailyChill = { current: [], dates: [] };
  
  const dynamicChillUnitsModel = new DynamicChillUnitsModel();
  for (let i = 0; i < dates.length; i++) {
    const currDate = dates[i];
    const addToTimeseries = !isAfter(parseISO(`2020-${currDate.slice(5,10)}`), parseISO(`2020-${seasonEDate.slice(5,10)}`));
    const isToday = currDate.slice(5,10) === seasonEDate.slice(5,10);
    const startHour = i * 24;
    const endHour = startHour + 24;
    
    for (let j = startHour; j < endHour; j++) {
      // Use Spline to calculate the hourly temp
      const hourlyTemp = spline.at(j);

      // Use hourly temp to calculate hourly chill units
      const chill = chillModel(hourlyTemp, dynamicChillUnitsModel);
      // Add chill units to sum, but ensure that the sum never goes below 0
      chillSum = Math.max(0, chillSum + chill);

      // Check if new sum is new max
      if (chillSum > chillMax[1]) {
        chillMax = [currDate, chillSum];
      }

      // If it is today, then set value
      if (isToday) {
        onTodayChill = chillSum;
      }

      // Check if chill unit sum has crossed any of the thresholds provided in thresholdArr
      for (let j = 0; j < ts.length; j++) {
        const threshold = ts[j];

        // Store the date that the threshold was crossed
        if (chillSum >= threshold) {
          ts[j] = {
            dateOccurred: currDate,
            ...calcChillSeasonDates(currDate),
          };
        }
      }
    }
    
    if (addToTimeseries) {
      dailyChill.dates.push(currDate.slice(5));
      dailyChill.current.push(chillSum);
    }
  }
  return { onTodayChill, chillMax, thresholdsCrossed: ts, chillSum, dailyChill };
};

const calcGddSeasonDates = (dateOccurred, seasonStartDay) => {
  if (!dateOccurred) dateOccurred = '2021-01-01';

  // If date occurred is a on Feb. 29th, change to Mar. 1st to avoid error
  if (dateOccurred.slice(5) === '02-29') {
    dateOccurred = dateOccurred.slice(0,5) + '03-01';
  }

  // Handle adjusting for leap years
  let doObj = parseISO(dateOccurred);
  if (isLeapYear(doObj) && !isBefore(doObj, new Date(doObj.getFullYear(), 2, 1))) {
    doObj = addDays(doObj, 1);
  }

  // Coerce to predefined year in order to chart day of season instead of actual date
  const dayOfSeason = Date.parse(format(doObj, '2021-MM-dd'));

  
  const daysIntoSeason = differenceInDays(
    new Date(dayOfSeason),
    parseISO('2021' + seasonStartDay)
  );

  return { dayOfSeason, daysIntoSeason };
};

const calcChillSeasonDates = (seasonEDate) => {
  if (!seasonEDate) seasonEDate = FAKE_CHILL_SEASON_START_STR;

  // If date occurred is a on Feb. 29th, change to Mar. 1st to avoid error
  if (seasonEDate.slice(5) === '02-29') {
    seasonEDate = seasonEDate.slice(0,5) + '03-01';
  }

  // Handle adjusting for leap years
  let doObj = parseISO(seasonEDate);
  if (isLeapYear(doObj) && !isBefore(doObj, new Date(doObj.getFullYear(), 2, 1))) {
    doObj = addDays(doObj, 1);
  }

  // Coerce to predefined year in order to chart day of season instead of actual date
  const year = parseInt(seasonEDate.slice(5,7)) < 9 ? '2021' : '2020';
  const dayOfSeason = Date.parse(format(doObj, `${year}-MM-dd`));

  const daysIntoSeason = differenceInDays(
    new Date(dayOfSeason),
    FAKE_CHILL_SEASON_START
  );

  return { dayOfSeason, daysIntoSeason };
};

const combineMonths = (seasonEDate, monthsArr, sortedThresholds, chillModel) => {
  const yearSummary = monthsArr.reduce(
    (monthAcc, { spline, dates }) => {
      const results = calcChillMax(
        seasonEDate,
        dates,
        spline,
        monthAcc.thresholds,
        monthAcc.chillSum,
        monthAcc.chillMax,
        chillModel
      );
      return {
        chillSum: results.chillSum,
        chillMax:
          monthAcc.chillMax[1] > results.chillMax[1]
            ? monthAcc.chillMax
            : results.chillMax,
        onTodayChill: results.onTodayChill || monthAcc.onTodayChill,
        thresholds: results.thresholdsCrossed.map((t, i) =>
          typeof monthAcc.thresholds[i] === 'number' && typeof t !== 'number'
            ? t
            : monthAcc.thresholds[i]
        ),
        dailyChill: {
          current: monthAcc.dailyChill.current.concat(results.dailyChill.current),
          dates: monthAcc.dailyChill.dates.concat(results.dailyChill.dates)
        }
      };
    },
    {
      chillSum: 0,
      chillMax: ['', 0],
      onTodayChill: 0,
      thresholds: sortedThresholds,
      dailyChill: { current: [], dates: [] }
    }
  );

  const dateOccurred = yearSummary.chillMax[0];
  return {
    values: yearSummary.chillMax[1],
    thresholds: yearSummary.thresholds,
    onTodayChill: yearSummary.onTodayChill,
    dateOccurred,
    ...calcChillSeasonDates(dateOccurred),
    dailyChill: yearSummary.dailyChill
  };
};

const addToObj = (keyList, newData, accObj) => {
  keyList.forEach((keyName) => {
    accObj[keyName].push(
      newData[keyName] === undefined ? null : newData[keyName]
    );
  });
};

const thresholdsKeys = ['dateOccurred', 'dayOfSeason', 'daysIntoSeason'];
const chillMaxsKeys = thresholdsKeys.concat(['values']);

const addYearToChartDataObj = (
  seasonEDate,
  year,
  monthsArr,
  yearAcc,
  sortedThresholds,
  chillModel,
  includeDailyChill=false
) => {
  const yearResults = combineMonths(seasonEDate, monthsArr, sortedThresholds, chillModel);
  yearAcc.years.push(year);

  const dailyChill = JSON.parse(JSON.stringify(yearResults.dailyChill));
  delete yearResults.dailyChill;

  yearAcc.onToday.values.push(yearResults.onTodayChill);
  addToObj(chillMaxsKeys, yearResults, yearAcc.maxs);

  yearResults.thresholds.forEach((thresholdRes, i) => {
    addToObj(thresholdsKeys, thresholdRes, yearAcc.thresholds[i]);
  });

  if (includeDailyChill) {
    return {
      dailyChill: {
        ...dailyChill,
        last: [],
        normal: []
      },
      yearAcc
    };
  }
  return yearAcc;
};

const calcChillChartData = (chillModel) => {
  return function(splinesArr, thresholds, seasonSDate, seasonEDate) {
    const sortedThresholds = thresholds.sort((a, b) => b - a);
    const currYearSplines = splinesArr.pop();
    const defaultObj = {
      years: [],
      maxs: {
        dateOccurred: [],
        values: [],
        dayOfSeason: [],
        daysIntoSeason: [],
      },
      onToday: {
        dateOccurred: seasonEDate,
        values: [],
        ...calcChillSeasonDates(seasonEDate)
      },
      thresholds: Array.from({ length: sortedThresholds.length }, (v, i) => ({
        threshold: sortedThresholds[i],
        dateOccurred: [],
        dayOfSeason: [],
        daysIntoSeason: [],
      })),
    };
    const { yearAcc: newCurrentSeasonChartData, dailyChill} = addYearToChartDataObj(
      seasonEDate,
      ...currYearSplines,
      JSON.parse(JSON.stringify(defaultObj)),
      sortedThresholds,
      chillModel,
      true
    );

    const newChartData = splinesArr.reduce(
      (yearAcc, [year, monthsArr]) => {
        return addYearToChartDataObj(
          seasonEDate,
          year,
          monthsArr,
          yearAcc,
          sortedThresholds,
          chillModel
        );
      },
      JSON.parse(JSON.stringify(defaultObj)),
    );

    newChartData.timeseries = dailyChill;
    return { newChartData, newCurrentSeasonChartData };
  }
};

const calcGddChartData = (gdds, thresholds, seasonSDate=null, seasonEDate=null) => {
  const seasonStartDay = seasonSDate ? seasonSDate.slice(5,10) : '01-01';
  const seasonEndDay = seasonEDate ? seasonEDate.slice(5,10) : gdds[gdds.length - 1][0].slice(5,10)
  
  const sortedThresholds = [...thresholds].sort((a, b) => a - b);
  const newCurrentSeasonChartData = null;
  const newChartData = {
    years: [],
    onToday: {
      dateOccurred: null,
      values: [],
      dayOfSeason: null,
      daysIntoSeason: null
    },
    thresholds: Array.from({ length: sortedThresholds.length }, (v, i) => ({
      threshold: sortedThresholds[i],
      dateOccurred: [],
      dayOfSeason: [],
      daysIntoSeason: [],
    })),
    timeseries: {
      dates: [],
      current: [],
      last: [],
      normal: []
    }
  };

  const normalSums = {};

  let lastSeasonStartIdx = null;
  let lastSeasonEndIdx = null;
  let currSum = 0;
  let inTimeseries = false;
  let ts;
  for (let i = 0; i < gdds.length; i++) {
    const [strDate, dayGddAmount] = gdds[i];

    if (strDate.slice(5) === seasonStartDay) {
      lastSeasonStartIdx = i;
      // Add the ending year for the new season
      newChartData.years.push(parseInt(strDate.slice(0,4)) + (seasonStartDay === '01-01' ? 0 : 1));
      // Set values to initial state
      currSum = 0;
      inTimeseries = true;

      if (ts) {
        ts.forEach(t => {
          const tObj = newChartData.thresholds.find(obj => obj.threshold === t);
          tObj.dateOccurred.push(null);
          tObj.dayOfSeason.push(null);
          tObj.daysIntoSeason.push(null);
        });
      }

      ts = [...sortedThresholds];
    }

    if (inTimeseries) {
      currSum += dayGddAmount;
      if (!(strDate.slice(5) in normalSums)) normalSums[strDate.slice(5)] = { sum: 0, count: 0 };
      normalSums[strDate.slice(5)].sum += currSum;
      normalSums[strDate.slice(5)].count += 1;
      
      if (ts.length && ts[0] <= currSum) {
        const { daysIntoSeason: currDaysIntoSeason, dayOfSeason: currDayOfSeason } = calcGddSeasonDates(strDate, seasonStartDay);
        const t = ts[0];
        const tObj = newChartData.thresholds.find(obj => obj.threshold === t);
        tObj.dateOccurred.push(strDate);
        tObj.dayOfSeason.push(currDayOfSeason);
        tObj.daysIntoSeason.push(currDaysIntoSeason);
        ts.shift();
      }
      
      if (strDate.slice(5) === seasonEndDay) {
        lastSeasonEndIdx = i;
        let { daysIntoSeason: d1, dayOfSeason: d2 } = calcGddSeasonDates(strDate, seasonStartDay);
        newChartData.onToday.daysIntoSeason = d1;
        newChartData.onToday.dayOfSeason = d2;
        newChartData.onToday.dateOccurred = strDate;
        newChartData.onToday.values.push(currSum);
        inTimeseries = false;
      }
    }
  }

  let currSeasonSum = 0;
  if (lastSeasonEndIdx < lastSeasonStartIdx) {
    lastSeasonEndIdx = gdds.length - 1;
  }
  gdds.slice(lastSeasonStartIdx, lastSeasonEndIdx + 1).forEach(([date, gdd]) => {
    const monthDay = date.slice(5);
    if (monthDay !== '02-29') {
      currSeasonSum += gdd;
      newChartData.timeseries.current.push(roundXDigits(currSeasonSum,0));
      newChartData.timeseries.dates.push((monthDay));
    }
  });

  return { newChartData, newCurrentSeasonChartData };
};

function calcChartData(values, thresholds, chartDataFunc, seasonSDate=null, seasonEDate=null) {
  if (values.length) {
    const { newChartData, newCurrentSeasonChartData } = chartDataFunc(
      [...values],
      [...thresholds], 
      seasonSDate,
      seasonEDate
    );

    if ('maxs' in newChartData) {
      newChartData.maxs.subsetSize = 10;
      newChartData.maxs.valuesRunningAverage = calcRunningAverage(
        newChartData.maxs.subsetSize,
        newChartData.maxs.values
      );
      newChartData.maxs.dateOccurredRunningAverage = calcRunningAverage(
        newChartData.maxs.subsetSize,
        newChartData.maxs.dayOfSeason
      );
  
      newChartData.maxs.avgDaysIntoSeason = Math.round(newChartData.maxs.daysIntoSeason.reduce((a,b) => a + b, 0) / newChartData.maxs.daysIntoSeason.length);
    }

    newChartData.onToday.subsetSize = 10;
    newChartData.onToday.valuesRunningAverage = calcRunningAverage(
      newChartData.onToday.subsetSize,
      newChartData.onToday.values
    );

    newChartData.thresholds = newChartData.thresholds.map((tObj) => {
      tObj.avgDaysIntoSeason = Math.round(tObj.daysIntoSeason.reduce((a,b) => a + b, 0) / tObj.daysIntoSeason.length);
      return tObj;
    });

    return { newChartData, newCurrentSeasonChartData };
  }

  return { newChartData: null, newCurrentSeasonChartData: null };
}

function refreshChillChartData(seasonEDate, splines, thresholds, chillModel) {
  return calcChartData(splines, thresholds, calcChillChartData(chillModel), null, seasonEDate);
}

function refreshGddChartData(gdds, thresholds, seasonSDate, seasonEDate) {
  return calcChartData(gdds, thresholds, calcGddChartData, seasonSDate, seasonEDate);
}

export { refreshChillChartData, refreshGddChartData, getSplines };