import ListSelector, { defaultProps as listDefaultProps } from "./list-selector.component";
import DepartureSelector, { defaultProps as departureDefaultProps } from "./departure-selector.component";
import ThresholdSelector, { defaultProps as thresholdDefaultProps } from "./threshold-selector/threshold-selector.component";
import DateSelector, { defaultProps as dateDefaultProps } from "./date-selector.component";


const optionsSelectorInformation = {
  listSelector: {
    component: ListSelector,
    defaultProps: listDefaultProps
  },
  departureSelector: {
    component: DepartureSelector,
    defaultProps: departureDefaultProps
  },
  thresholdSelector: {
    component: ThresholdSelector,
    defaultProps: thresholdDefaultProps
  },
  dateSelector: {
    component: DateSelector,
    defaultProps: dateDefaultProps
  }
}

export default optionsSelectorInformation;