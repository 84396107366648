import React, { useContext } from 'react';

import { GlobalContext } from '../../contexts/global.context';

import './legend.styles.scss';

export default function Legend() {
  const { display, overlayBinThresholds, overlayBinColors, getSelectorValueByIdFromTabInformation } = useContext(GlobalContext);

  const selectedRegions = getSelectorValueByIdFromTabInformation('regions') || [];
  if (selectedRegions.length === 0) {
    return '';
  }

  const comparison = getSelectorValueByIdFromTabInformation('overlayKey') || '';
  const squares = overlayBinColors ? overlayBinColors.map(c => 
    <div key={c} className='legend-square'>
      <div className='legend-square-spacer'></div>
      <div className='legend-square-color-block' style={{ backgroundColor: c }}></div>
    </div>
  ) : [];
  const labels = overlayBinThresholds ? overlayBinThresholds.map(t => <div key={t} className='legend-label'>{t}%</div>) : [];

  return (
    <div className='legend-container' style={{ display: display === 'map' ? 'flex' : 'none' }}>
      {comparison && <div className='legend-title'>Departure from {comparison}</div>}
      <div className='legend-labels-container'>{labels}</div>
      <div className='legend-squares-container'>{squares}</div>
    </div>
  );
}
