import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import TooltipSeason from '../../components/tooltip-season/tooltip-season.component';

const COLOR1 = '#C42020';

export default function calcPestTimeseriesOptions(chartData, address, dataName) {
  const timeseries = [...chartData.timeseries.current];
  while (timeseries.length && timeseries[timeseries.length - 1] === null) {
    timeseries.pop();
  }
  const dates = chartData.timeseries.dates.slice(0, timeseries.length);
  const min = 0;
  const max = Math.max(...timeseries);

  let emergenceThreshold = chartData.emergenceAtGdds;
  const emergenceLines = timeseries.reduce((acc, gdds, idx) => {
    if (gdds >= emergenceThreshold) {
      emergenceThreshold += chartData.emergenceAtGdds;
      acc.push({
        color: 'rgb(200,200,200)',
        label: {
          text: `Gen. ${acc.length + 1}`,
          style: {
            color: 'rgb(150,150,150)',
          },
        },
        value: idx,
      });
    }
    return acc;
  }, []);

  return {
    chart: {
      marginRight: 20,
    },
    title: {
      text: `${dataName} Accumulation Throughout Season with ${chartData.pest} Emergence Dates`,
    },
    subtitle: {
      text: `Location: ${address}`,
    },
    series: [
      {
        data: timeseries,
        name: 'Current Season',
        color: COLOR1,
      }
    ],
    xAxis: {
      categories: dates,
      crosshair: {
        color: 'rgb(220,220,220)',
        width: 0.5,
      },
      title: {
        text: 'Day of Year',
      },
      plotLines: emergenceLines
    },
    yAxis: [
      {
        min,
        max,
        startOnTick: false,
        endOnTick: false,
        tickAmount: 5,
        gridLineWidth: 0,
        title: {
          text: dataName,
        },
        labels: {
          style: {
            fontFamily:
              'Roboto, "Helvetica Neue", Verdana, Arial, Helvetica, sans-serif',
          },
        },
      },
    ],
    legend: {
      enabled: false,
    },
    tooltip: {
      shared: true,
      outside: true,
      useHTML: true,
      backgroundColor: 'white',
      formatter: function () {
        if (!this || !this.points) return '';

        const point = this.points[0];
        const gdds = point.y;
        const date = point.x;

        return renderToStaticMarkup(
          <div className='max-chill-tooltip-content-container'>
            <TooltipSeason year={date} color={COLOR1} />

            <div className='mc-container'>
              <div className='mc-number'>{gdds}</div>
              <div className='mc-units'>{dataName}</div>
            </div>
          </div>
        );
      },
    },
  };
}
