import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { parseISO, formatISO } from 'date-fns';

import Accordion from '../accordion/accordion.component';

const defaultProps = {
  value: new Date(),
  label: '',
  pickerLabel: '',
  minDate: (today) => new Date(1900,0,1),
  maxDate: (today) => new Date(2099,11,31),
  today: new Date()
};

export default function DateSelector({
  expanded,
  handleChange,
  handleChangeSelection,
  id,
  value=defaultProps.value,
  label=defaultProps.label,
  pickerLabel=defaultProps.pickerLabel,
  minDate=defaultProps.minDate,
  maxDate=defaultProps.maxDate,
  today=defaultProps.today
}) {
  if (today === null || today === undefined) return '';

  return (
    <Accordion expanded={expanded === id} handleChange={handleChange} id={id} label={label} >
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DatePicker
          label={pickerLabel}
          value={parseISO(value)}
          onChange={(newValue) => handleChangeSelection(formatISO(newValue))}
          slotProps={{ textField: { InputLabelProps: { shrink: true } } }}
          minDate={minDate(today)}
          maxDate={maxDate(today)}
        />
      </LocalizationProvider>
    </Accordion>
  );
}

export { defaultProps };